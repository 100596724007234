import classnames from 'classnames';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Contact"
      description="Contact information for Denmark based artist Katharina Welper"
    />
    <div className={classnames('intro', 'intro-contact')}>
      <div className="about" id="about">
        <h2>Contact</h2>
        <ul className="contact-list">
          <li>
            <strong>Email:</strong>{' '}
            <a href="mailto:kathawelper@gmail.com">kathawelper@gmail.com</a>
          </li>
          <li>
            <strong>Instagram:</strong>{' '}
            <a
              href="https://www.instagram.com/katharinawelper"
              target="_blank"
              rel="noopener noreferrer"
            >
              instagram.com/katharinawelper
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
